module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"google":{"families":["Poppins:300,400,500,600,700","Chango:400"]}},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"minimal-ui","icon":"src/assets/image/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"f9b0f2e8b4ea69143f57c61649a6ae19"},
    },{
      plugin: require('../node_modules/gatsby-plugin-gatsby-cloud/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-source-prismic/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"lucianaluciernaga","accessToken":"MC5ZTFdSd2hBQUFDUUFmYjhE.Ru-_ve-_vXw677-977-9GEzvv71M77-977-9C--_vUoEV--_vTZG77-9Y--_vV_vv73vv71eBO-_vQxI","schemas":{"testimonio":{"Main":{"nombre":{"type":"StructuredText","config":{"single":"heading1","label":"Nombre","placeholder":"Nombre"}},"titulo":{"type":"StructuredText","config":{"single":"heading2","label":"Título","placeholder":"Título"}},"texto":{"type":"StructuredText","config":{"multi":"paragraph, strong, em","label":"Texto","placeholder":"Texto"}}}}}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
